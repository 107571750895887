.main-title {
  font-family: 'Abril Fatface', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 5rem;
}

.sub-title {
  font-family: 'Abril Fatface', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 2rem;
  /*color: #214f3c;*/
  color: #292929;
}

@media (max-width: 480px) {
  .main-title {
    text-align: center;
    font-size: 3.5rem;
  }
  .sub-title {
    text-align: center;
    font-size: 1.75rem;
  }
  .socials {
    padding: 0;
  }
}

@media (max-width: 576px) {
  .main-title {
    text-align: center;
  }
  .sub-title {
    text-align: center;
  }
  .socials {
    padding: 0;
  }
}

@media (max-width: 576px) {
  .main-title {
    text-align: center;
  }
  .sub-title {
    text-align: center;
  }
  .socials {
    padding: 0;
  }
}

.title-row {
  min-height: 100vh;
  position: relative;
}

.title-row hr {
  width: 50%;
}

.socials {
  padding: 0;
  padding-top: 1em;
  list-style-type: none;
}

.socials > li {
  font-size: larger;
}

.socials > li > a *:first-child {
  margin-right: 1em;
}

.socials > li > a {
  transition: 0.1s cubic-bezier(.17,.67,.83,.67);
  text-decoration: none;
  color: black;
}

.socials > li:first-child > a:hover {
  color: #33ffba;
}

.socials > li:nth-child(2) > a:hover {
  color: #ffba33;
}

.socials > li:nth-child(3) > a:hover {
  color: #ff3378;
}

.socials > li:nth-child(4) > a:hover {
  color: #3378ff;
}

.job-subtitle > hr {
  vertical-align: middle;
}

.job-subtitle > * {
  display: inline-block;
}

.generic-subtitle > hr {
  vertical-align: middle;
}

.generic-subtitle > * {
  display: inline-block;
}

.job-image {
  transition: 0.1s cubic-bezier(.17,.67,.83,.67);
  filter: grayscale(100%);
  height: 4em;
  display: block;
  margin-top: 1em;
  margin-bottom: 1em;
}

.job-image:hover {
  filter: none;
}

.vr {
  margin: 0;
  margin-left: 0.5em;
  margin-right: 0.5em;
  border: none;
  border-left: 1px solid hsla(200, 10%, 50%,100);
  height: 2vh;
  width: 1px;  
}

.spacer {
  margin-left: auto;
  margin-right: auto;
}

.job:not(:last-child) {
  margin-bottom: 2em;
}

.footer {
  background-color: #292929;
  color: white;
}

.footer-logo {
  font-family: 'Abril Fatface', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 2rem;
}

.footer-list {
  list-style-type: none;
  padding-left: 0;
  line-height: 1.5em;
}

.footer-list > li > a {
  text-decoration: none;
  color: white;
}

.footer-list > li > a:hover {
  color: #33ffba;
}

.arrow {
  position: absolute;
  top: 95vh;
  width: 100%;
  text-align: center;
  font-size: larger;
}

.arrow svg {
  cursor: pointer;
  transition: 0.1s cubic-bezier(.17,.67,.83,.67);
}

.arrow svg:hover {
  transform-origin: center;
  transform: scale(1.5);
}

.avatar {
  border-radius: 100%;
  width: 10em;
  height: 10em;
  box-shadow: 0px 10px 15px -7px #C1C1C1;
}